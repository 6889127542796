import React from "react";
import AnimatedPage from "../../../components/ui/AnimatedPage";

import backgroundImage from "assets/img/tadam/textures/f0-louison.webp";

import LouisonCharacter from "assets/img/tadam/louison_personnage_v2.webp";
import InteractiveCharacter from "./character/InteractiveCharacter";
import WheelOfQuestions from "./questions/WheelOfQuestions.jsx";
import HeaderLouison from "./header/HeaderLouison.jsx";

import eclair from "assets/img/tadam/eclair_fond_2x.webp";

import PlaylistLouison from "./specials/PlaylistLouison.jsx";

export default function PageLouison({ switchPage }) {
	return (
		<AnimatedPage className='page-container page-container--home'>
			<HeaderLouison switchPage={switchPage} />
			<div className='frise-damier frise-damier--louison'></div>
			<InteractiveCharacter
				title={"Le vestiaire de Louison"}
				currentCharacter={"louison"}
				characterPicture={LouisonCharacter}
				backgroundImage={backgroundImage}
			/>
			<div className='frise-damier frise-damier--louison'></div>
			<WheelOfQuestions currentCharacter={"louison"} backgroundImage={backgroundImage}>
				<div className='background-image'></div>
				<img className='eclair eclair--gauche' src={eclair} alt='' />
				<img className='eclair eclair--droite' src={eclair} alt='' />
			</WheelOfQuestions>
			<div className='frise-damier frise-damier--louison'></div>
			<PlaylistLouison />
		</AnimatedPage>
	);
}
