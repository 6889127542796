import React, { useContext, useEffect, useRef, useState } from "react";
import "./Tadam.scss";
import Nav from "../components/Layout/Nav";
import { Outlet, useLocation } from "react-router";
import ModalContext from "../ModalContext";
import ModalTadamGameInstructions from "../Templates/ModalTemplates/Tadam/ModalTadamGameInstructions";
import QuestionMark from "../assets/lottie/foxes-question-mark-instructions.json";
import Lottie from "lottie-react";
import magicSmoke from "../assets/lottie/magic_smoke.json";
import Yann from "./PagesComponents/Tadam/Yann";
import Louison from "./PagesComponents/Tadam/Louison";
import Kiki from "./PagesComponents/Tadam/Kiki";

export default function Tadam() {
	const location = useLocation();
	// const fullUrlArray = location.pathname.split("/").filter(Boolean); // Supprime les segments vides
	// const currentCharacter = fullUrlArray.pop(); // Récupère le dernier élément
	const { modalContent, toggleModal, hasSeenTadamTutorial, setHasSeenTadamTutorial } = useContext(ModalContext);
	// const { hasSeenTutorial, setHasSeenTutorial } = useTadamStore();
	const [currentCharacter, setCurrentCharacter] = useState("yann");
	const magicSmokeRef = useRef(null);
	const handleModalClose = () => {
		setHasSeenTadamTutorial(true);
		toggleModal(false);
	};

	function switchPage(currentCharacter) {
		// Vérifier si la référence existe avant d'appeler des méthodes

		magicSmokeRef.current.goToAndPlay(0);

		setTimeout(() => {
			setCurrentCharacter(currentCharacter);
		}, 1000); // Ajustez le délai en fonction de la durée de votre animation
	}

	return (
		<main id='page-tadam' className={`page_container  page-tadam page-tadam--${currentCharacter} `}>
			<Nav />
			{/* {hasSeenTadamTutorial ? null : modalContent} */}
			{/* {ModalTadamGameInstructions} */}
			{hasSeenTadamTutorial ? null : <ModalTadamGameInstructions />}
			<Lottie
				className='magic-smoke'
				animationData={magicSmoke}
				loop={false}
				lottieRef={magicSmokeRef}
				speed={10.5}
				rendererSettings={{
					preserveAspectRatio: "none",
				}}
			/>
			<Lottie
				className='consignes'
				animationData={QuestionMark}
				loop
				autoplay
				onClick={() => setHasSeenTadamTutorial(false)}
			/>
			{currentCharacter === "yann" ? <Yann switchPage={switchPage} /> : null}
			{currentCharacter === "louison" ? <Louison switchPage={switchPage} /> : null}
			{currentCharacter === "kiki" ? <Kiki switchPage={switchPage} /> : null}
			<Outlet switchPage={switchPage} />
		</main>
	);
}
