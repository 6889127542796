import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "./ModalFoxesGameInstructions.scss";
import ModalContext from "../../../ModalContext";
import documentPedagogique from "../../../assets/pdf/FOXES_dossier_daccompagnement.pdf";
import Lottie from "lottie-react";
import Download from "assets/lottie/download.json";
import arrowDouble from "../../../assets/lottie/arrow6.json";
import blurryBackground from "../../../assets/img/foxes/Blurred--Abstract-Backgrounds_05.jpg";

export default function ModalFoxesGameInstructions() {
	const { toggleModal, closeModal } = useContext(ModalContext);

	return (
		<div id='modalfoxesgameinstruction' className='modal__wrapper modal__wrapper--blue'>
			<img className='blurry-background' src={blurryBackground} alt='' />
			<div className='text'>
				<h4>
					Bienvenue <br />
					dans La Liesse !
				</h4>
				<div className='modal__description'>
					<p>Explorez le dancing, cliquez sur les personnages, le renard, le néon…</p>
					<p>
						Vous retrouverez des éléments, des thématiques et des questions
						soulevées par le spectacle
						<br />
						<span className='oliver'>…et quelques surprises ! </span>
					</p>

					<button className='go cta-arrowed cta-arrowed--orangered' onClick={closeModal}>
						c'est parti !
					</button>
					<Lottie className='arrow' animationData={arrowDouble} loop autoplay />
					<p className='oliver'>
						À vous de jouer ! Trouvez tous les objets perdus pour rallumer La Liesse
						!
					</p>
				</div>

				<p>
					Si vous le souhaitez vous pouvez retrouver ces contenus et d’autres dans ce
					fichier :
				</p>

				<Link
					download={true}
					target='_blank'
					to={documentPedagogique}
					className='cta--blue cta--download'>
					Télécharger
					<Lottie
						className='download'
						animationData={Download}
						loop
						autoplay
						onClick={() => toggleModal(<ModalFoxesGameInstructions />)}
					/>
				</Link>
			</div>
		</div>
	);
}
