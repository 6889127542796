import React, { useRef } from "react";
import "./IleTadam.scss";

export default function IleGrou() {
	return (
		<>
			<div id='iletadam' className='Island'>
				<div className='video_container'>
					<video autoPlay muted loop playsInline>
						<source
							src='/videos/iles/20241219_ile_tadam-vp9-chrome.webm'
							type='video/webm'></source>
						<source
							src='/videos/iles/20241219_ile_tadam-hevc-safari.mp4'
							type='video/quicktime'></source>
					</video>
				</div>
				<div className='text'>
					<h2 className='island_title'>Tadam</h2>
					<p>Découvrez-en plus sur le spectacle et rebranchez La Liesse ! </p>
				</div>
			</div>
		</>
	);
}
