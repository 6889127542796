import React from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";

export default function AnimatedPage({ children, className }) {
	const location = useLocation();

	const animationParameters = {
		initial: { x: "50%", opacity: 0 },
		animate: { x: "0", opacity: 1 },
		exit: { x: "-50%", opacity: 0 },
	};

	return (
		<motion.div
			key={location.pathname}
			className={className}
			initial={animationParameters.initial}
			animate={animationParameters.animate}
			exit={animationParameters.exit}
			transition={{ duration: 0.3, ease: "easeOut" }}>
			{children}
		</motion.div>
	);
}
