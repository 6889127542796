import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Intro.scss";

import clouds from "../assets/img/intro/clouds.webp";
import dirigeable from "../assets/img/intro/dirigeable.webp";
import backRenardsicon from "../assets/img/elements/back_renards.svg";
import superBonusBlock from "../assets/img/super_bonus.webp";

export default function Intro() {
	const btnClickExplosion = useRef(null);
	const navigate = useNavigate();

	const handleClick = (e) => {
		e.preventDefault();
		const url = e.currentTarget.getAttribute("href");

		btnClickExplosion.current.style.left = e.pageX + "px";
		btnClickExplosion.current.style.top = e.pageY + "px";
		btnClickExplosion.current.play();

		setTimeout(() => {
			navigate(url);
		}, 360);
	};

	return (
		<main className='page_container sky intro_section'>
			<img className='clouds_1' src={clouds} width={704} height={462} alt='' />
			<img className='clouds_2' src={clouds} width={704} height={462} alt='' />
			<div className='link backrenards'>
				<img className='back_icon' src={backRenardsicon} alt='' />
				<Link to='https://cierenards.be/'>CieRenards</Link>
			</div>
			<video muted playsInline className='clickExplosion' ref={btnClickExplosion}>
				<source src='/videos/effects/07_glow-hevc-safari.mp4' type='video/quicktime'></source>
				<source src='/videos/effects/07_glow-vp9-chrome.webm' type='video/webm'></source>
			</video>

			<div className='container'>
				<img className='dirigeable' src={dirigeable} width={2052} height={1050} alt='' />
				<img className='font_bonus' src={superBonusBlock} width={1879} height={642} alt='' />
				<div className='cta-container'>
					<Link to='/iles' className='cta' onClick={(e) => handleClick(e)}>
						<span className='text'>C'est parti </span>
						<div className='round'>
							<div className='arrow'></div>
						</div>
					</Link>
				</div>
			</div>
		</main>
	);
}
