import React from "react";
import AnimatedPage from "../../../components/ui/AnimatedPage";
import WheelOfQuestions from "./questions/WheelOfQuestions.jsx";
import Chess from "./specials/Chess";

import kikiCharacter from "../../../assets/img/tadam/kiki-personnage-v2.webp";
import InteractiveCharacter from "./character/InteractiveCharacter";
import HeaderKiki from "./header/HeaderKiki.jsx";
import charactersDatas from "data/characters-bullets.json";
import charactersQuestions from "data/characters-questions.json";
import backgroundImage from "assets/img/tadam/textures/f0-kiki.webp";
import vignetteMotif from "assets/img/tadam/textures/vignette_motif_2x.webp";
// import WukongBoard from "./specials/WukongBoard";

export default function PageKiki({ switchPage }) {
	return (
		<AnimatedPage className='page-container page-container--home'>
			<HeaderKiki switchPage={switchPage} />
			<div className='frise-damier frise-damier--kiki'></div>
			<InteractiveCharacter
				title={"Le vestiaire de Kiki"}
				characterPicture={kikiCharacter}
				characterDatas={charactersDatas}
				currentCharacter={"kiki"}
				backgroundImage={backgroundImage}
			/>
			<div className='frise-damier frise-damier--kiki'></div>
			<WheelOfQuestions
				questionsDatas={charactersQuestions}
				currentCharacter={"kiki"}
				backgroundImage={backgroundImage}>
				<div className='background-image'></div>

				<img className='vignette' src={vignetteMotif} alt='' />
			</WheelOfQuestions>
			<div className='frise-damier frise-damier--kiki'></div>

			<Chess />
		</AnimatedPage>
	);
}
