import React, { useRef, useEffect } from "react";
import "./Dialog.scss";

export default function Dialog({ children, onClose, className = "", id = "", open = false }) {
	const dialogRef = useRef(null);

	useEffect(() => {
		if (open) {
			dialogRef.current?.showModal();
		} else {
			dialogRef.current?.close();
		}
	}, [open]);

	const handleClose = () => {
		if (onClose) onClose();
	};

	return (
		<dialog ref={dialogRef} onClose={handleClose} className={`dialog ${className}`} id={id}>
			<div className='dialog__wrapper'>
				<button
					onClick={handleClose}
					className='dialog__close-button'
					aria-label='Fermer'></button>

				{children}
			</div>
		</dialog>
	);
}
