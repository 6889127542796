import React, { createContext, useState } from "react";
import Modal from "./components/Modals/Modal";

const ModalContext = createContext();

export function ModalProvider({ children }) {
	const [modalContent, setModalContent] = useState(false);
	const [centerModalVertically, setCenterModalVertically] = useState(null);
	const [hasSeenTadamTutorial, setHasSeenTadamTutorial] = useState(false);

	document.onkeydown = function (e) {
		if (e.key === "Escape") {
			toggleModal(false);
		}
	};
	function closeModal() {
		toggleModal(false);
	}
	function toggleModal(component) {
		if (typeof component == "object") {
			setModalContent(<Modal closeModal={closeModal}>{component}</Modal>);
		} else {
			setModalContent(null);
		}
	}
	function calculateModalDisposition(modalContent) {
		const modal = document.querySelector("#modal .modal__content");
		if (!modal) return;

		if (modal.clientHeight > window.innerHeight - 40) {
			setCenterModalVertically(false);
		} else {
			setCenterModalVertically(true);
		}
	}
	return (
		<ModalContext.Provider
			value={{
				modalContent,
				toggleModal,
				closeModal,
				setModalContent,
				centerModalVertically,
				calculateModalDisposition,
				hasSeenTadamTutorial,
				setHasSeenTadamTutorial,
			}}>
			{children}
		</ModalContext.Provider>
	);
}
export default ModalContext;
