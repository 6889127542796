import React, { useEffect } from "react";
import "./ModalCredits.scss";

import FoxTails from "../../../components/ui/FoxTails";

import creditSoundFile from "../../../assets/music/creditSound.mp3"; // importing the music
import fontSuperBonus from "../../../assets/img/credits/superbonusfont.webp";
import antoineIcon from "../../../assets/img/credits/antoine.webp";
import polIcon from "../../../assets/img/credits/pol.webp";
import baptisteIcon from "../../../assets/img/credits/baptiste.svg";
import arthurIcon from "../../../assets/img/credits/arthur.webp";

export default function ModalCredits() {
	const creditSound = new Audio(creditSoundFile);
	useEffect(() => {
		creditSound.play();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<FoxTails />

			<div id='modalavantlespectacle' className='modal__wrapper modal__wrapper--blue'>
				<h4 className='credit_title'>Credits</h4>
				<img id='credit_title_font' src={fontSuperBonus} alt='' />
				<div className='credits_container'>
					<div className='pol credit-col credit-col--dessin'>
						<img id='pol' className='character' src={polIcon} alt='' />
						<h5 className='fonction'>Dessins et animations</h5>
						<p>Pol Bourdellon</p>
					</div>
					<div className='antoine credit-col credit-col--dev'>
						<img id='antoine' className='character' src={antoineIcon} alt='' />
						<h5 className='fonction'>Design et Développement</h5>
						<p>Antoine Martinon</p>
					</div>
					<div className='credit-col credit-col--direction'>
						<div className='duo'>
							<img
								id='baptiste'
								className='character'
								src={baptisteIcon}
								alt=''
							/>
							<img
								id='arthur'
								className='character'
								src={arthurIcon}
								alt=''
							/>
						</div>

						<h5 className='fonction'>Direction artistique</h5>
						<p>Arthur Oudar</p>
						<p>Baptiste Toulemonde</p>
					</div>
				</div>
			</div>
		</>
	);
}
