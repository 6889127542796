import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "./ModalTadamGameInstructions.scss";
import ModalContext from "../../../ModalContext";
import documentPedagogique from "../../../assets/pdf/dossier_acccompagnement-tadam.pdf";
import Lottie from "lottie-react";
import Download from "assets/lottie/download.json";
import arrowDouble from "../../../assets/lottie/arrow6.json";
// import blurryBackground from "../../../assets/img/foxes/Blurred--Abstract-Backgrounds_13.jpg";
import blurryBackground from "../../../assets/img/foxes/3.jpg";
// import blurryBackground from "../../../assets/img/foxes/4.jpg";
import Dialog from "../../../components/Modals/Dialog";

export default function ModalTadamGameInstructions() {
	const { hasSeenTadamTutorial, setHasSeenTadamTutorial } = useContext(ModalContext);

	const handleClose = () => {
		setHasSeenTadamTutorial(true);
	};

	return (
		<Dialog
			open={!hasSeenTadamTutorial}
			onClose={handleClose}
			id='modaltadamgameinstruction'
			className='modal-tadam'>
			<img className='blurry-background' src={blurryBackground} alt='' />
			<div className='text'>
				<h4>
					Bienvenue <br />
					dans Tadam !
				</h4>
				<div className='modal__description'>
					<p>
						Un chapeau de magicien, un sac à dos et une tête de mort… Trois univers
						à ouvrir et à découvrir pour{" "}
						<span className='oliver'>se souvenir, s’amuser et réfléchir !</span>
						<br />
					</p>

					<button className='go cta-arrowed cta-arrowed--orangered' onClick={handleClose}>
						c'est parti !
					</button>
					<Lottie className='arrow' animationData={arrowDouble} loop autoplay />
					<p className='oliver text-center'>À vous de jouer !</p>
				</div>

				<p>
					Pour celles et ceux qui voudraient aller plus loin vous pouvez retrouver plein
					d’autres contenus dans notre dossier d’accompagnement… Ici !
				</p>

				<Link
					download={true}
					target='_blank'
					to={documentPedagogique}
					className='cta--blue cta--download'>
					Télécharger
					<Lottie className='download' animationData={Download} loop autoplay />
				</Link>
			</div>
		</Dialog>
	);
}
