import React, { useEffect } from "react";
import "./Chess.scss";
import colonneGreen from "assets/img/tadam/colonne_green_2x.webp";
import vignette from "assets/img/tadam/textures/f3-kiki.webp";
import chessboard from "assets/img/tadam/chessboard_2x.webp";

export default function Chess() {
	return (
		<section className={`tadam-section  tadam-section--chess-kiki`}>
			<h2 className='tadam-section__title'>
				Le Défi <br /> de Kiki
			</h2>
			<p className='tadam-section__description'>
				Tente-toi aussi de gagner une partie contre Kiki ! Les blancs commencent, à toi de jouer{" "}
			</p>
			<video className='video-pattern video-pattern--spiral' autoPlay muted loop playsInline>
				<source src='/videos/tadam/spinning_spiral.mp4' type='video/mp4'></source>
				<source
					src='/videos/tadam/spiral_background-vp9-chrome.webm'
					type='video/webm'></source>
			</video>
			<div className='chessboard'>
				<div className='chessboard-game-container'>
					<img className='chessboard__game-img' src={chessboard} alt='' />
					<div className='chessboard__game'>
						<iframe
							src='https://fritz.chessbase.com'
							height='600px'
							width='600px'></iframe>
					</div>
				</div>
			</div>
			<img className='colonne-green colonne-green--left' src={colonneGreen} alt='' />
			<img className='colonne-green colonne-green--right' src={colonneGreen} alt='' />
			<img className='vignette' src={vignette} alt='' />
			<div className='background-image'></div>
		</section>
	);
}
