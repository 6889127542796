import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { QuizzProvider } from "./QuizzContext";
import { ModalProvider } from "./ModalContext";
import client from "./services/appolo.js";
import { AnimatePresence } from "framer-motion";
import HomeIslands from "./Pages/HomeIslands";
import Intro from "./Pages/Intro";
import Foxes from "./Pages/Foxes";
import Tadam from "./Pages/Tadam";
import AvantLeSpectacle from "./Pages/AvantLeSpectacle";
import CourriersDuCoeur from "./Pages/CourriersDuCoeur";
import QuizzIntro from "./Pages/QuizzIntro.jsx";
import QuizzPlay from "./Pages/QuizzPlay.jsx";
import QuizzResultat from "./Pages/QuizzResultat.jsx";
import GrouLeJeu from "./Pages/GrouLeJeu.jsx";
import Testing from "./Pages/Testing";
import ChessGame from "./Pages/ChessGame";
import TestPage from "./Pages/TestPage.jsx";
import Yann from "./Pages/PagesComponents/Tadam/Yann";
import Kiki from "./Pages/PagesComponents/Tadam/Kiki";
import Louison from "./Pages/PagesComponents/Tadam/Louison";

function App() {
	const location = useLocation();

	return (
		<>
			<ModalProvider>
				<QuizzProvider>
					<ApolloProvider client={client}>
						<AnimatePresence mode='wait'>
							<Routes location={location} key={location.pathname}>
								<Route index element={<Intro />} />
								<Route path='/iles' element={<HomeIslands />} />
								<Route path='/foxes' element={<Foxes />} />
								<Route path='/tadam' element={<Tadam />} />
								{/* <Route path='tadam' element={<Tadam />}>
									<Route
										index
										element={<Navigate to='yann' replace />}
									/>
									<Route path='yann' element={<Yann />} />
									<Route path='louison' element={<Louison />} />
									<Route path='kiki' element={<Kiki />} />
								</Route> */}

								<Route path='/testing' element={<Testing />} />
								<Route path='/test-page' element={<TestPage />} />
								<Route path='/chess' element={<ChessGame />} />
								<Route
									path='/avant-le-spectacle'
									element={<AvantLeSpectacle />}
								/>
								<Route
									path='/courriers-du-coeur'
									element={<CourriersDuCoeur />}
								/>
								<Route path='/quizz' element={<QuizzIntro />} />
								<Route path='/quizz/play' element={<QuizzPlay />} />
								<Route
									path='/quizz/resultat'
									element={<QuizzResultat />}
								/>
								<Route path='/grou-le-jeu' element={<GrouLeJeu />} />
							</Routes>
						</AnimatePresence>
					</ApolloProvider>
				</QuizzProvider>
			</ModalProvider>
		</>
	);
}

export default App;
