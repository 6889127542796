import React from "react";
import AnimatedPage from "../../../components/ui/AnimatedPage";

import yannCharacter from "assets/img/tadam/YANN_personnage.png";
import InteractiveCharacter from "./character/InteractiveCharacter";
import WheelOfQuestions from "./questions/WheelOfQuestions.jsx";
import HeaderYann from "./header/HeaderYann.jsx";
import charactersQuestions from "data/characters-questions.json";
import backgroundImage from "assets/img/textures/background-yann.webp";
import colonne from "assets/img/tadam/colonne_droite.webp";
import Tutos from "./specials/Tutos.jsx";
import AnimatedButton from "components/ui/AnimatedButton";
import LottieClickAnimation from "components/ui/LottieClickAnimation";
import circleCloud from "assets/lottie/circle-cloud-01.json";
import circleCloud2 from "assets/lottie/circle-cloud-02.json";
import test from "assets/lottie/circle-cloud-03.json";
export default function PageYann({ switchPage }) {
	return (
		<AnimatedPage className='page-container page-container--home'>
			{/* <LottieClickAnimation className='lottie-click-animation' animationData={test} /> */}
			{/* <AnimatedButton to={`/tadam/louison`}></AnimatedButton> */}
			<HeaderYann switchPage={switchPage} />
			<div className='frise-damier frise-damier--yann'></div>
			<InteractiveCharacter
				title={"Le vestiaire de Yann"}
				currentCharacter={"yann"}
				characterPicture={yannCharacter}
				backgroundImage={backgroundImage}
			/>
			<div className='frise-damier frise-damier--yann'></div>
			<WheelOfQuestions
				questionsDatas={charactersQuestions}
				currentCharacter={"yann"}
				backgroundImage={backgroundImage}>
				<div className='background-image'></div>
				<img className='colonne colonne--gauche' src={colonne} alt='' />
				<img className='colonne colonne--droite' src={colonne} alt='' />
			</WheelOfQuestions>
			<div className='frise-damier frise-damier--yann'></div>
			<Tutos />
		</AnimatedPage>
	);
}
