import React, { useRef } from "react";
import { Link } from "react-router-dom";
import "./Nav.scss";
import { ReactComponent as ReactLogo } from "./bars_menu.svg";
import superBonusBlock from "../../assets/img/super_bonus.webp";
import dirigeable from "../../assets/img/intro/dirigeable.webp";
import backRenardsicon from "../../assets/img/elements/back_renards.svg";

import closeMenu from "../../assets/img/elements/closemenu.svg";
export default function Nav() {
	const fixedMenu = useRef(null);

	const showHideMenu = () => {
		fixedMenu.current.classList.toggle("is_open");
	};

	return (
		<>
			<nav className='navbar'>
				<Link to='/'>
					<img className='logo_font_bonus' src={superBonusBlock} alt='' />
				</Link>
				<button className='open_menu' onClick={showHideMenu}>
					<ReactLogo />
				</button>
			</nav>
			<nav ref={fixedMenu} className='fixed_menu'>
				<img className='close_menu' src={closeMenu} onClick={showHideMenu} alt='' />
				<ul>
					<li>
						<Link to='/'>
							<img className='dirigeable' src={dirigeable} alt='' />
						</Link>
					</li>
					<li className='link'>
						<Link to='/iles'>Menu</Link>
					</li>
					<li className='link'>
						<Link to='/avant-le-spectacle'>Avant le spectacle</Link>
					</li>
					<li className='link'>
						<Link to='/quizz'>Quizz</Link>
					</li>
					<li className='link'>
						<Link to='/courriers-du-coeur'>Courriers du coeur</Link>
					</li>
					<li className='link'>
						<Link to='/foxes'>Foxes</Link>
					</li>
					<li className='link'>
						<Link to='/tadam'>Tadam</Link>
					</li>
					<li className='link'>
						<Link
							to='https://games.gdevelop-app.com/game-d0b9a29e-3132-4142-9d81-fd3ac8613bf5/index.html'
							target='_blank'>
							Grou Le jeu
						</Link>
					</li>
					<li className='link'>
						{/* <Link to='/grou'>Grou Le jeu</Link> */}
						<Link target='_blank' to='https://cierenards.be/theatre-jeune-public/'>
							Théâtre jeune public
						</Link>
					</li>

					<li className='link backrenards'>
						<img className='back_icon' src={backRenardsicon} alt='' />
						<Link to='https://cierenards.be/'>CieRenards</Link>
					</li>
				</ul>
			</nav>
		</>
	);
}
