import React, { useEffect, useRef, useState } from "react";
import Lottie from "lottie-react";
import "./WheelOfQuestions.scss";

import RoueQuestionYann from "assets/img/tadam/roue_des_questions_yann_2x.webp";
import RoueQuestionLouison from "assets/img/tadam/roue_question_louison_2x.webp";
import RoueQuestionKiki from "assets/img/tadam/roue_des_questions_kiki_2x.webp";

import charactersQuestions from "data/characters-questions.json";
import wheelInnerCircle from "assets/img/tadam/wheel-inner.webp";

import circleCloud from "assets/lottie/circle-cloud-03.json";

export default function WheelOfQuestions({ currentCharacter, backgroundImage, children }) {
	const characterAllQuestions = charactersQuestions[currentCharacter] || null;
	const [isVisible, setIsVisible] = useState(false);
	const circleAnimationRef = useRef(null);

	const [rotation, setRotation] = useState(0);
	const [selectedQuestion, setSelectedQuestion] = useState(null);
	const [remainingQuestionsToAsk, setRemainingQuestionsToAsk] = useState([...characterAllQuestions]);
	const [isButtonActive, setIsButtonActive] = useState(true);

	const questionDialogRef = useRef(null);
	const [shouldRestartWheel, setShouldRestartWheel] = useState(false);

	function playAnimation() {
		setIsVisible(true);

		if (circleAnimationRef.current) {
			circleAnimationRef.current.goToAndPlay(0);
		}
	}

	useEffect(() => {
		if (circleAnimationRef.current) {
			circleAnimationRef.current.goToAndPlay(0);
		}
	}, []);

	function askQuestion() {
		setTimeout(() => {
			// Récupérer la question au moment où le timeout expire, et non pas avant
			const newQuestion = remainingQuestionsToAsk[0];
			setSelectedQuestion(newQuestion);
			questionDialogRef.current.showModal();
			setIsButtonActive(true);
		}, 3000); // Temps correspondant à la durée de l'animation
	}

	function handleWheelClick(e) {
		playAnimation();
		spinWheel();
		askQuestion();
	}

	function spinWheel() {
		setIsButtonActive(false);
		const totalQuestions = 8;
		const anglePerQuestion = 360 / totalQuestions;

		const randomIndex = Math.floor(Math.random() * totalQuestions);
		let newRotation = rotation + 360 * 3 + randomIndex * anglePerQuestion;

		if (rotation === 0) {
			newRotation += anglePerQuestion / 2;
		}

		setRotation(newRotation);
	}

	function updateAnsweredQuestions() {
		if (remainingQuestionsToAsk.length > 0) {
			setRemainingQuestionsToAsk(remainingQuestionsToAsk.slice(1));
		} else {
			setRemainingQuestionsToAsk([...characterAllQuestions]);
		}
	}

	useEffect(() => {
		if (shouldRestartWheel && remainingQuestionsToAsk.length === characterAllQuestions.length) {
			handleWheelClick();
			setShouldRestartWheel(false);
		}
	}, [remainingQuestionsToAsk, shouldRestartWheel, characterAllQuestions]);

	function handleRestart() {
		// Indiquer qu'on veut redémarrer la roue
		setShouldRestartWheel(true);
		// Réinitialiser les questions
		setRemainingQuestionsToAsk([...characterAllQuestions]);
	}

	if (!remainingQuestionsToAsk) {
		return <p>pas de question</p>;
	}
	return (
		<section
			className={`tadam-section tadam-section--question-wheel tadam-section--question-wheel--${currentCharacter}`}>
			<video className='video-pattern video-pattern--damier-2' autoPlay muted loop playsInline>
				<source src='/videos/tadam/checkerbackground2.mp4' type='video/quicktime'></source>
				<source
					src='/videos/tadam/checkerbackground2-vp9-chrome.webm'
					type='video/webm'></source>
			</video>
			{children}

			<h2 className='tadam-section__title has-sparkle'>La roue des questions</h2>
			<p className='tadam-section__description'>
				Voici une roue pleine de questions dont on a perdu les réponses, sapristi ! Mais toi ?
				Qu'est-ce que tu en dis ?
			</p>
			<p className='tadam-section__description completed-questions-container'>
				<span className='completed-questions-label'>Questions complétées: </span>

				<span className='answer-count'>
					{characterAllQuestions.length - remainingQuestionsToAsk.length}
				</span>
				<span className='total-count'>/ {characterAllQuestions.length}</span>
			</p>
			<div className='wheel-container'>
				<Lottie
					style={{
						display: isVisible ? "block" : "none",
					}}
					className='circle-cloud-animation'
					animationData={circleCloud}
					loop={false}
					lottieRef={circleAnimationRef}
					onComplete={() => setIsVisible(false)}
				/>
				{/* <div className='wheel' style={{ transform: `rotate(${rotation}deg)` }}></div> */}
				<img
					className='wheel-inner-circle'
					style={{ transform: `rotate(${rotation}deg)` }}
					src={wheelInnerCircle}
					alt=''
				/>
				{currentCharacter === "yann" && (
					<img className='wheel-habillage' src={RoueQuestionYann} alt='' />
				)}
				{currentCharacter === "louison" && (
					<img className='wheel-habillage' src={RoueQuestionLouison} alt='' />
				)}
				{currentCharacter === "kiki" && (
					<img className='wheel-habillage' src={RoueQuestionKiki} alt='' />
				)}
				<dialog
					className='question-box'
					ref={questionDialogRef}
					onClose={() => updateAnsweredQuestions()}>
					<video
						className='video-pattern video-pattern--damier-1'
						autoPlay
						muted
						loop
						playsInline>
						<source
							src='/videos/tadam/checkerbackground.mp4'
							type='video/quicktime'></source>
						<source
							src='/videos/tadam/checkerbackground-vp9-chrome.webm'
							type='video/webm'></source>
					</video>
					<div className='question-content'>
						<button
							onClick={() => questionDialogRef.current.close()}
							className='close-button'>
							✖
						</button>
						{selectedQuestion && (
							<p className='selected-question'> {selectedQuestion}</p>
						)}
					</div>
				</dialog>
			</div>
			{remainingQuestionsToAsk.length > 0 ? (
				<button onClick={handleWheelClick} className='spin-button' disabled={!isButtonActive}>
					<span>Tourner la roue</span>
				</button>
			) : (
				<button
					onClick={handleRestart}
					className='spin-button spin-button--restart'
					disabled={!isButtonActive}>
					<span>Recommencer</span>
				</button>
			)}
		</section>
	);
}
