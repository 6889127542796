import React from "react";

import escalier from "assets/img/tadam/escalier_2x.webp";
import backgroundImage from "assets/img/tadam/textures/f0-kiki.webp";
import boneArm from "assets/img/tadam/bone_3x.webp";
import { Link } from "react-router-dom";
import prevNextArrow from "assets/img/tadam/tadam_previous_next_arrow.png";
import Lottie from "lottie-react";
import arrowScrollDown from "assets/lottie/arrow_scroll_down.json";
export default function HeaderKiki({ switchPage }) {
	return (
		<section className='header header--kiki'>
			<img className='escalier escalier--left' src={escalier} alt='' />
			<img className='escalier escalier--right' src={escalier} alt='' />
			<img className='bone-arm bone-arm--left' src={boneArm} alt='' />
			<img className='bone-arm bone-arm--right' src={boneArm} alt='' />
			<div className='background-image'></div>

			<Lottie
				className='arrow-scroll-down'
				animationData={arrowScrollDown}
				loop
				autoplay
				rendererSettings={{
					preserveAspectRatio: "none",
				}}
			/>

			<video className='video-pattern video-pattern--damier-1' autoPlay muted loop playsInline>
				<source src='/videos/tadam/checkerbackground.mp4' type='video/quicktime'></source>
				<source
					src='/videos/tadam/checkerbackground-vp9-chrome.webm'
					type='video/webm'></source>
			</video>
			<div className='content-container'>
				<button
					className='btn-change-character btn-change-character--previous'
					onClick={() => switchPage("yann")}>
					<img src={prevNextArrow} className='arrow-icon' alt='' />
					<span>Yann</span>
				</button>
				<button
					className='btn-change-character btn-change-character--next'
					onClick={() => switchPage("louison")}>
					<span>Louison</span>
					<img src={prevNextArrow} className='arrow-icon' alt='' />
				</button>
				<video className='video video--crane' autoPlay muted loop playsInline>
					<source src='/videos/tadam/crane-vp9-chrome.webm' type='video/webm'></source>
					<source
						src='/videos/tadam/crane-hevc-safari.mp4'
						type='video/quicktime'></source>
				</video>
				<h1>
					Le crâne <br />à kiki
				</h1>
			</div>
		</section>
	);
}
