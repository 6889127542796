import React from "react";
import { Link } from "react-router-dom";
import "./ModalJeunePublic.scss";
import arrow from "../../../assets/lottie/arrow18.json";
import Lottie from "lottie-react";

export default function ModalJeunePublic() {
	return (
		<div id='modaljeunepublic' className='modal__wrapper modal__wrapper--blue'>
			<div className='text'>
				<h4>Pourquoi du Théâtre Jeune public ?</h4>
				<div className='modal__description'>
					<p>
						Sous l'impulsion du Théâtre de la Montagne Magique, Baptiste et Arthur
						ont eu une petite conversation pour essayer de réfléchir à la question…
					</p>
					<p>
						En fait…<span className='oliver'> Pourquoi vous faites ça ?</span>
					</p>
				</div>
				<Lottie className='arrow' animationData={arrow} loop autoplay />

				<Link
					target='_blank'
					to='https://cierenards.be/theatre-jeune-public/'
					className='cta-arrowed cta-arrowed--orangered'>
					c'est par ici
				</Link>
			</div>
		</div>
	);
}
