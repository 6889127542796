import React from "react";
import "./ModalFoxesChoices.scss";
import ModalFoxesDommage from "./ModalFoxesDommage";
import ModalFoxesChouette from "./ModalFoxesChouette";
import VideoModule from "../../../Pages/PagesComponents/AvantSpectacle/VideoSpectacle";
import arrow from "../../../assets/lottie/arrow25.json";
import Lottie from "lottie-react";

export default function ModalFoxes({ toggleModal }) {
	return (
		<div id='modal-foxes-choices' className='modal__wrapper modal__wrapper--blue'>
			<h4>Bonus Foxes</h4>

			<div className='content'>
				<div className='text'>
					<h5>Attention !</h5>

					<p>
						Vous entrez dans les <span className='oliver'>bonus</span> du spectacle{" "}
						<span className='oliver'>« FOXES » !</span> Avez-vous déjà
						<span className='oliver'> vu le spectacle « FOXES » ?</span>
					</p>
				</div>
			</div>

			<Lottie className='arrow' animationData={arrow} loop autoplay />

			<div className='buttons'>
				<VideoModule
					webmUrl='/videos/modals/lampe_VP9.webm'
					mp4Url='/videos/modals/lampe-hevc-safari.mp4'
					loop={true}
				/>
				<div className='button_container'>
					<button
						className='round-button round-button--blue'
						onClick={() => toggleModal(<ModalFoxesDommage />)}>
						non
					</button>

					<button
						className='round-button round-button--orange'
						onClick={() => toggleModal(<ModalFoxesChouette />)}>
						oui
					</button>
				</div>
				<VideoModule
					webmUrl='/videos/modals/lampe_VP9.webm'
					mp4Url='/videos/modals/lampe-hevc-safari.mp4'
					loop={true}
				/>
			</div>
		</div>
	);
}
