import React, { useEffect, useRef, useState } from "react";
import "./WheelOfFortune.scss";

export default function WheelOfFortune() {
	const prizesList = [
		{ text: "Une glace au chocolat", color: "hsl(197 30% 43%)" },
		{ text: "Pas d'école demain !", color: "hsl(173 58% 39%)" },
		{ text: "Poisson à la cantine", color: "hsl(43 74% 66%)" },
		{ text: "Une barre de chocoshmoutz", color: "hsl(27 87% 67%)" },
		{ text: "Manger une crotte de nez", color: "hsl(12 76% 61%)" },
		{ text: "Ballade en forêt", color: "hsl(350 60% 52%)" },
	];

	const [availablePrizes, setAvailablePrizes] = useState([...prizesList]);
	const [rotation, setRotation] = useState(0);

	const wheel = useRef(null);
	const spinner = useRef(null);
	const trigger = useRef(null);
	const ticker = useRef(null);
	let prizeNodes;

	const prizeSlice = 360 / prizesList.length;
	const prizeOffset = Math.floor(180 / prizesList.length);
	const spinClass = "is-spinning";
	const selectedClass = "selected";

	useEffect(() => {
		setupWheel();
	}, []);

	const setupWheel = () => {
		createConicGradient();
		createPrizeNodes();
		prizeNodes = wheel.current.querySelectorAll(".prize");
	};

	const createPrizeNodes = () => {
		spinner.current.innerHTML = "";
		prizesList.forEach(({ text, color }, i) => {
			const rotation = prizeSlice * i * -1 - prizeOffset;
			spinner.current.insertAdjacentHTML(
				"beforeend",
				`<li class="prize" style="--rotate: ${rotation}deg">
					<span class="text">${text}</span>
				</li>`
			);
		});
	};

	const createConicGradient = () => {
		spinner.current.setAttribute(
			"style",
			`background: conic-gradient(
				from -90deg,
				${prizesList.map(({ color }, i) => `${color} 0 ${(100 / prizesList.length) * (prizesList.length - i)}%`).reverse()}
			);`
		);
	};

	const spinWheel = () => {
		if (availablePrizes.length === 0) {
			setAvailablePrizes([...prizesList]);
		}

		const prizeIndex = Math.floor(Math.random() * availablePrizes.length);
		const selectedPrize = availablePrizes[prizeIndex];

		const newRotation = rotation + spinertia(2000, 5000);
		setRotation(newRotation);

		wheel.current.classList.add(spinClass);
		spinner.current.style.setProperty("--rotate", newRotation);

		setTimeout(() => {
			selectPrize(selectedPrize.text);
			wheel.current.classList.remove(spinClass);
			spinner.current.style.setProperty("--rotate", newRotation % 360);

			setAvailablePrizes((prev) => prev.filter((prize) => prize.text !== selectedPrize.text));
		}, 3000);
	};

	const selectPrize = (prizeText) => {
		prizeNodes.forEach((prize) => {
			if (prize.textContent.trim() === prizeText) {
				prize.classList.add(selectedClass);
			} else {
				prize.classList.remove(selectedClass);
			}
		});
	};

	const spinertia = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

	return (
		<section className='wheel-container' id='wheel-container'>
			<div className='deal-wheel' ref={wheel}>
				<ul className='spinner' ref={spinner}></ul>
				<div className='ticker' ref={ticker}></div>
				<button
					className='btn-spin'
					ref={trigger}
					onClick={spinWheel}
					disabled={availablePrizes.length === 0}>
					Tente ta chance
				</button>
			</div>
		</section>
	);
}
