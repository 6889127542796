import React from "react";

import guitare from "assets/img/tadam/guitare_louison_2x.webp";
import eclair from "assets/img/tadam/eclair_front.webp";
import damiermotif from "assets/img/tadam/damier_louison_drapeau.webp";
import backgroundImage from "assets/img/textures/background-louison.webp";
import prevNextArrow from "assets/img/tadam/tadam_previous_next_arrow.png";
import "./Header.scss";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import arrowScrollDown from "assets/lottie/arrow_scroll_down.json";
export default function HeaderLouison({ switchPage }) {
	return (
		<section className='header header--louison'>
			<Lottie
				className='arrow-scroll-down'
				animationData={arrowScrollDown}
				loop
				autoplay
				rendererSettings={{
					preserveAspectRatio: "none",
				}}
			/>
			<img className='damier-motif damier-motif--left' src={damiermotif} alt='' />
			<img className='damier-motif damier-motif--right' src={damiermotif} alt='' />
			<img className='guitare' src={guitare} alt='' />
			<img className='eclair eclair--left' src={eclair} alt='' />
			<img className='eclair eclair--right' src={eclair} alt='' />
			<div className='background-image background-image--louison'></div>
			{/* <img className='background-image' src={backgroundImage} alt='' /> */}
			<video className='video-pattern video-pattern--damier-1' autoPlay muted loop playsInline>
				<source src='/videos/tadam/checkerbackground.mp4' type='video/quicktime'></source>
				<source
					src='/videos/tadam/checkerbackground-vp9-chrome.webm'
					type='video/webm'></source>
			</video>
			<div className='content-container'>
				<button
					className='btn-change-character btn-change-character--previous'
					onClick={() => switchPage("kiki")}>
					<img src={prevNextArrow} className='arrow-icon' alt='' />
					<span>Kiki</span>
				</button>
				<button
					className='btn-change-character btn-change-character--next'
					onClick={() => switchPage("yann")}>
					<span>Yann</span>

					<img src={prevNextArrow} className='arrow-icon' alt='' />
				</button>
				<video className='video video--sac' autoPlay muted loop playsInline>
					<source src='/videos/tadam/sac-vp9-chrome.webm' type='video/webm'></source>
					<source src='/videos/tadam/sac-hevc-safari.mp4' type='video/quicktime'></source>
				</video>
				<h1>
					Le sac <br />
					de Louison
				</h1>
			</div>
		</section>
	);
}
