import React from "react";
import rideau from "assets/img/tadam/rideau_gauche.png";
import star_droite from "assets/img/tadam/star_droite.png";
import "./Tutos.scss";
import ostheopate from "assets/img/tadam/ostheo_pate_2x.webp";

export default function Tutos() {
	return (
		<section className={`tadam-section  tadam-section--tutos-yann`}>
			<img className='rideau rideau--left' src={rideau} alt='' />
			<img className='rideau rideau--right' src={rideau} alt='' />
			<img className='star star--left' src={star_droite} alt='' />
			<img className='star star--right' src={star_droite} alt='' />
			<div className='background-circle'></div>
			<h2 className='tadam-section__title has-sparkle'>
				Les tutos <br /> de l'Ostéo-pâtes
			</h2>
			<video className='video-pattern video-pattern--spiral' autoPlay muted loop playsInline>
				<source src='/videos/tadam/spinning_spiral.mp4' type='video/mp4'></source>
				<source
					src='/videos/tadam/spiral_background-vp9-chrome.webm'
					type='video/webm'></source>
			</video>
			<div className='video'>
				<img className='ostheopate' src={ostheopate} alt='' />
				<iframe
					width='560'
					height='315'
					src='https://www.youtube.com/embed/UquqL8XRSC4?si=6pguRaLGr6BBRJqo'
					title='YouTube video player'
					frameBorder='0'
					allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
					referrerPolicy='strict-origin-when-cross-origin'
					allowFullScreen></iframe>
			</div>
			<div className='background-image'></div>
		</section>
	);
}
