import React, { useState } from "react";
import schema from "../../assets/img/testing/schema_1.png";
// import "./BonhomeHover.scss";

export default function BonhomeHover() {
	const [activeBullet, setActiveBullet] = useState(null);
	return (
		<div className="bonhome-hover-container">
			
			<section className='schema-container'>
				<button id='bullet_1' className='bullet' onClick={() => setActiveBullet(1)}></button>
				<button id='bullet_2' className='bullet' onClick={() => setActiveBullet(2)}></button>
				<button id='bullet_3' className='bullet' onClick={() => setActiveBullet(3)}></button>
				<img src={schema} alt='' />
			</section>
			<div className='text-content'>
				{activeBullet === 1 && (
					<div className='text-content__explanation'>
						<h2>Bulle 1</h2>
						<p>
							Lorem ipsum, dolor sit amet consectetur adipisicing elit. Autem,
							aut voluptatem! Sapiente veritatis eligendi natus minima itaque
							maxime, eum ipsam doloremque expedita dignissimos beatae quia
							eius deleniti dolorum, fugiat maiores.
						</p>
					</div>
				)}
				{activeBullet === 2 && (
					<div className='text-content__explanation'>
						<h2>Bulle 2</h2>
						<p>
							Lorem ipsum, dolor sit amet consectetur adipisicing elit. Autem,
							aut voluptatem! Sapiente veritatis eligendi natus minima itaque
							maxime, eum ipsam doloremque expedita dignissimos beatae quia
							eius deleniti dolorum, fugiat maiores.
						</p>
					</div>
				)}
				{activeBullet === 3 && (
					<div className='text-content__explanation'>
						<h2>Bulle 3</h2>
						<p>
							Lorem ipsum, dolor sit amet consectetur adipisicing elit. Autem,
							aut voluptatem! Sapiente veritatis eligendi natus minima itaque
							maxime, eum ipsam doloremque expedita dignissimos beatae quia
							eius deleniti dolorum, fugiat maiores.
						</p>
					</div>
				)}
			</div>
		</div>
	);
}
