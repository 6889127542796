import React, { useRef, useState } from "react";
import Lottie from "lottie-react";
import "./InteractiveCharacter.scss";
import charactersDatas from "data/characters-bullets.json";
import circleCloud from "assets/lottie/circle-cloud-01.json";

export default function InteractiveCharacter({ title, characterPicture, backgroundImage, currentCharacter }) {
	const [activeBullet, setActiveBullet] = useState(null);

	const currentCaracterDatas = charactersDatas[currentCharacter];

	const [isAnimationVisible, setIsAnimationVisible] = useState(false);
	const circleAnimationRef = useRef(null);

	const [bulletPosition, setBulletPosition] = useState({ x: 0, y: 0 });

	function handleClick(e) {
		// get the x and y of the click
		const x = e.clientX;
		const y = e.clientY;
		setBulletPosition({ x, y });
		console.log(bulletPosition);
		setIsAnimationVisible(true);

		if (circleAnimationRef.current) {
			circleAnimationRef.current.goToAndPlay(0);
		}
	}

	return (
		<section className='character-interaction tadam-section--character-interaction'>
			<div className='background-image'></div>
			<video className='video-pattern video-pattern--spiral' autoPlay muted loop playsInline>
				<source src='/videos/tadam/spinning_spiral.mp4' type='video/mp4'></source>
				<source
					src='/videos/tadam/spiral_background-vp9-chrome.webm'
					type='video/webm'></source>
			</video>
			<div className='section-title'>
				<h2 className='tadam-section__title has-sparkle'>{title}</h2>
				<p className='tadam-section__description'>
					clique sur les différents points et redécouvre qui est {currentCharacter}
				</p>
			</div>

			<div className='character-container'>
				<div className='picture-container'>
					<Lottie
						style={{
							display: isAnimationVisible ? "block" : "none",
							top: `${bulletPosition.y}px`,
							left: `${bulletPosition.x}px`,
						}}
						lottieRef={circleAnimationRef}
						className='bullet-cloud-animation'
						animationData={circleCloud}
						loop={false}
						onComplete={() => setIsAnimationVisible(false)}
					/>
					{currentCaracterDatas.map((point, index) => (
						<div
							className={`bullet ${activeBullet === index ? "active" : ""} `}
							key={index}
							style={{
								left: `${point.x}%`,
								top: `${point.y}%`,
							}}>
							<button
								key={index}
								id={`bullet_${index}`}
								className={`bullet-btn ${
									activeBullet === index ? "active" : ""
								}`}
								// onMouseOver={() => setActiveBullet(index)}
								onClick={(e) => {
									handleClick(e);
									setActiveBullet(index);
								}}></button>
							<div
								className={`bullet-explanation ${
									activeBullet === index ? "active" : ""
								} ${point.x > 50 ? "is-right" : "is-left"}`}>
								<h3>{point.title}</h3>
								<p>{point.subtitle}</p>
							</div>
						</div>
					))}
					<div
						className={`character-background character-background--${currentCharacter}`}
						alt=''
					/>
					<img className='character' src={characterPicture} alt='' />
				</div>
			</div>
		</section>
	);
}
