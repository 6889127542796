import React, { useRef, useState } from "react";
import "./PlaylistLouison.scss";
import guitare from "assets/img/tadam/guitare_louison_2x.webp";

import Plyr from "plyr-react";
import "plyr-react/plyr.css";
import heroesAudioFile from "assets/music/Heroes_bounce.mp3";
import LifeOnMarsAudioFile from "assets/music/Life_on_mars_bounce.mp3";
import StarmanAudioFile from "assets/music/Starman_bounce.mp3";

import websiteIcon from "assets/img/foxes/player/website-icon.svg";
import phone from "assets/img/tadam/gsm_wrapper_3x.webp";
import profilePicLouison from "assets/img/tadam/profile_louison_pic.webp";
export default function PlaylistLouison() {
	const plyrRef = useRef();

	const traskList = [
		{
			title: "Starman",
			artist: "David Bowie",
			src: StarmanAudioFile,
			type: "audio/mp3",
			credits: "Tintoretto Music (BMI) All rights on behalf of Tintoretto Music administered by Warner Chappell Music Belgium NV",
		},
		{
			title: "Life on Mars",
			artist: "David Bowie",
			src: LifeOnMarsAudioFile,
			type: "audio/mp3",
			credits: "Tintoretto Music (BMI) and Unknown Publisher (NS), All rights on behalf of Tintoretto Music administered by Warner Chappell Music Belgium NV",
		},
		{
			title: "Heroes",
			artist: "Brian Eno & David Bowie",
			credits: "Tintoretto Music (BMI) All rights on behalf of Tintoretto Music administered by Warner Chappell Music Belgium NV",
			src: heroesAudioFile,
			type: "audio/mp3",
		},
	];
	const [currentTrackIndex, setCurrentTrackIndex] = useState(0);

	const plyrProps = {
		source: {
			type: "audio",
			autoPlay: false,
			sources: [traskList[currentTrackIndex]],
		},
		autoPlay: false,
		options: {
			// Notez l'ajout de "options" ici
			controls: ["play", "progress", "mute"],
			// i18n: {
			// 	play: "Lecture",
			// 	pause: "Pause",
			// 	played: "Lu",
			// 	buffered: "Chargé",
			// 	currentTime: "Temps actuel",
			// 	duration: "Durée",
			// 	volume: "Volume",
			// 	mute: "Muet",
			// 	unmute: "Activer le son",
			// },
		},
	};
	function playNextTrack() {
		if (currentTrackIndex < traskList.length - 1) {
			let newIndex = currentTrackIndex + 1;
			setCurrentTrackIndex(newIndex);
		} else {
			setCurrentTrackIndex(0);
		}
	}
	function playPreviousTrack() {
		console.log("previous track", currentTrackIndex);

		if (currentTrackIndex > 0) {
			setCurrentTrackIndex(currentTrackIndex - 1);
		} else {
			setCurrentTrackIndex(traskList.length - 1);
		}
	}
	return (
		<section className='tadam-section tadam-section--playlist-louison'>
			<h2 className='tadam-section__title has-sparkle'>
				La Playlist <br />
				de Louison
			</h2>
			<div className='background-image'></div>
			<video className='video-pattern video-pattern--spiral' autoPlay muted loop playsInline>
				<source src='/videos/tadam/spinning_spiral.mp4' type='video/mp4'></source>
				<source
					src='/videos/tadam/spiral_background-vp9-chrome.webm'
					type='video/webm'></source>
			</video>

			<div className='player'>
				<img className='phone-wrapper' src={phone} alt='' />{" "}
				<img className='profile-pic' src={profilePicLouison} alt='' width={300} />
				<div className='audio-player'>
					<div className='audio-player__current-infos'>
						<p className='audio-player__current-track-index'>
							{currentTrackIndex + 1} / {traskList.length}
						</p>
						<p className='audio-player__current-track-name'>
							{traskList[currentTrackIndex].title}
						</p>
						<p className='audio-player__current-artist'>
							{traskList[currentTrackIndex].artist}
						</p>
					</div>

					<div className='audio-player__control-wrapper'>
						<Plyr ref={plyrRef} {...plyrProps} />

						<div className='audio-player__prevnext-buttons'>
							<button className='previous-button' onClick={playPreviousTrack}>
								<svg aria-hidden='true' focusable='false'>
									<use xlinkHref='#plyr-rewind'></use>
								</svg>
							</button>
							<button className='next-button' onClick={playNextTrack}>
								<svg aria-hidden='true' focusable='false'>
									<use xlinkHref='#plyr-fast-forward'></use>
								</svg>
							</button>
						</div>
					</div>

					<p className='audio-player__author-infos'>
						Les chansons du spectacle{" "}
						<i>
							<b> «&nbsp;Tadam&nbsp;»</b>
						</i>{" "}
						sont de David Bowie et ont été arrangées par{" "}
						<a
							className='author'
							target='_blank'
							rel='noreferrer'
							href='https://guillaumevesin.wixsite.com/guillaumevesin'>
							{/* <img className='website-icon' src={websiteIcon} alt='' /> */}
							<span>Guillaume Vésin</span>
						</a>
						, avec l'aimable autorisation de Warner Chapell Music.
					</p>
				</div>
				<p className='audio-player__current-track-credits'>
					© {traskList[currentTrackIndex].credits}
				</p>
			</div>

			<div className='background-circle'></div>
			<img className='guitare guitare--left' src={guitare} alt='' />
			<img className='guitare guitare--right' src={guitare} alt='' />
		</section>
	);
}
