import React from "react";
import "./Header.scss";
import Lottie from "lottie-react";
import rideau from "assets/img/tadam/rideau_gauche.png";
import star_droite from "assets/img/tadam/star_droite.png";
import prevNextArrow from "assets/img/tadam/tadam_previous_next_arrow.png";
import arrowScrollDown from "assets/lottie/arrow_scroll_down.json";

export default function HeaderYann({ switchPage }) {
	return (
		<section className='header header--yann'>
			<Lottie
				className='arrow-scroll-down'
				animationData={arrowScrollDown}
				loop
				autoplay
				rendererSettings={{
					preserveAspectRatio: "none",
				}}
			/>
			<img className='rideau rideau--left' src={rideau} alt='' />
			<img className='rideau rideau--right' src={rideau} alt='' />
			<img className='star star--left' src={star_droite} alt='' />
			<img className='star star--right' src={star_droite} alt='' />

			<div className='background-image'></div>

			<video className='video-pattern video-pattern--damier-1' autoPlay muted loop playsInline>
				<source src='/videos/tadam/checkerbackground.mp4' type='video/quicktime'></source>
				<source
					src='/videos/tadam/checkerbackground-vp9-chrome.webm'
					type='video/webm'></source>
			</video>
			<div className='content-container'>
				<button
					className='btn-change-character btn-change-character--previous'
					onClick={() => switchPage("louison")}>
					<img src={prevNextArrow} className='arrow-icon' alt='' />
					<span>Louison</span>
				</button>

				<button
					className='btn-change-character btn-change-character--next'
					onClick={() => switchPage("kiki")}>
					<span>Kiki</span>
					<img src={prevNextArrow} className='arrow-icon' alt='' />
				</button>

				<video className='video video--chapeau' autoPlay muted loop playsInline>
					<source src='/videos/tadam/chapeau-vp9-chrome.webm' type='video/webm'></source>
					<source
						src='/videos/tadam/chapeau-hevc-safari.mp4'
						type='video/quicktime'></source>
				</video>
				<h1>
					Le chapeau <br />
					de yann
				</h1>
			</div>
		</section>
	);
}
